<div class="flex flex-col justify-center p-0 md:relative md:p-4xl" *transloco="let t">
  <div class="relative bottom-0 left-0 right-0 top-0 z-0 order-2 h-[190px] md:absolute md:h-full">
    <div
      class="background-gradient absolute z-20 h-full w-full rounded-b-3 md:rounded-t-3"
      [class.background-full-content-gradient]="fullContent"
    ></div>
    <img
      class="z-10 h-full w-full rounded-b-3 object-cover object-top md:rounded-t-3"
      [ngSrc]="section.background.url"
      [alt]="section.background.title"
      fill
    />
    @if (section.partnerLogo; as logo) {
      <div class="absolute bottom-lg left-0 right-0 z-30 md:bottom-2xl md:right-2xl">
        <div class="flex justify-center md:justify-end">
          <img
            class="max-w-20 rounded bg-white object-cover px-lg py-sm opacity-80"
            [ngSrc]="logo.url"
            [alt]="logo.title"
            [width]="logo.width"
            [height]="logo.height"
          />
        </div>
      </div>
    }
  </div>
  <div
    class="relative z-10 order-1 rounded-t-3 bg-grey-900 p-lg pb-xl text-center md:rounded-none md:bg-transparent md:p-0 md:text-left"
  >
    <div class="text-white" [ngClass]="{'md:w-2/5': !fullContent}">
      <h2 class="heading-2xl">{{ section.title }}</h2>
      <p class="mt-xs text-base">
        {{ section.content }}
      </p>
      <cft-call-to-action
        class="mt-lg inline-block"
        callToActionStyle="button"
        [showIcon]="false"
        [callToAction]="section.callToAction"
        anchorId="promotional-banner-call-to-action"
      ></cft-call-to-action>
    </div>
  </div>
</div>
