<div #container class="relative !z-0 overflow-hidden rounded-3 border border-grey-300 no-scrollbar">
  <table
    class="table-auto"
    [ngClass]="{'cursor-grab select-auto': !scrollTable, 'cursor-grabbing select-none': scrollTable}"
    cft-table
    [dataSource]="tableContent"
    recycleRows
    (mousedown)="drag($event)"
    (mouseup)="drag($event)"
    (mousemove)="drag($event)"
  >
    @for (col of columns; track col; let first = $first) {
      <ng-container [cftColumnDef]="col" [sticky]="first" [stickyBackground]="true">
        <th
          cft-header-cell
          *cftHeaderCellDef
          class="grid-divider bg-grey-50 text-[16px] leading-[140%] text-president-500"
          [class.whitespace-nowrap]="first"
        >
          <span [innerHTML]="col"></span>
        </th>
        <td cft-cell *cftCellDef="let entry" class="grid-divider hyphens-auto !text-wrap align-text-top">
          <span [innerHTML]="entry[col]"></span>
        </td>
      </ng-container>
    }
    <tr cft-header-row *cftHeaderRowDef="columns" class="!bg-grey-50"></tr>
    <tr cft-row *cftRowDef="let entry; columns: columns"></tr>
  </table>
</div>
