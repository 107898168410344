<cft-wave-cover-image class="rounded-3 -z-1">
  <img
    [ngSrc]="section().image.url"
    [alt]="section().image.title || ''"
    class="object-cover"
    fill
    content="background-image"
  />
</cft-wave-cover-image>

<div class="flex w-full justify-center px-xl pb-lg pt-2xl text-white">
  <div class="max-w-56 space-y-2xl" *transloco="let t">
    <div class="space-y-xl">
      <div class="space-y-2xs text-center">
        <h2 class="text-balance heading-xl">{{ section().title }}</h2>
        <p class="text-pretty text-sm">{{ section().description }}</p>
      </div>
      <form
        [formGroup]="form"
        class="space-y-xl [&_.ng-invalid.ng-touched]:outline [&_.ng-invalid.ng-touched]:outline-red-500"
      >
        <div class="space-y-sm">
          <div class="flex flex-col justify-between gap-sm md:flex-row">
            <input
              id="firstName"
              [formControl]="controls.firstName"
              class="placeholder-president-80 inline-block w-full rounded-3 bg-white px-md py-sm text-primary"
              [placeholder]="t('signupSection.firstNameLabel')"
            />
            <input
              id="lastName"
              [formControl]="controls.lastName"
              class="placeholder-president-80 inline-block w-full rounded-3 bg-white px-md py-sm text-primary"
              [placeholder]="t('signupSection.lastNameLabel')"
            />
          </div>
          <div>
            <input
              id="email"
              [formControl]="controls.email"
              class="placeholder-president-80 inline-block w-full rounded-3 bg-white px-md py-sm text-primary"
              [placeholder]="t('signupSection.emailLabel')"
            />
          </div>
        </div>
        <div class="flex w-full justify-center">
          <button
            type="button"
            class="btn btn-vermilion w-full md:w-3/4"
            (click)="submitLead()"
            cft-button
            [isLoading$]="isLoading$"
          >
            {{ section().form.buttonLabel }}
          </button>
        </div>
      </form>
    </div>
    <div class="text-grey-70 flex justify-center gap-xs text-sm font-medium">
      <fa-icon [icon]="lockIcon"></fa-icon>
      <span [innerHTML]="t('signupSection.privacyText')"></span>
    </div>
  </div>
</div>
